import React from 'react';
import { LoadingOverlay } from 'onsolve-ui-components';

import './style.scss';

const LoadingBarSection = () => <LoadingOverlay className="onsolve-loading-bar__wrapper" logoType="newLogo" />;

LoadingBarSection.displayName = 'LoadingBarSection';

export default LoadingBarSection;
