import React, { Component, Suspense, useEffect, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router';
import PropTypes from 'prop-types';

import { DEFAULT_LANGUAGE } from './common/constants/values';
import { ClientContext, LanguageContext } from './providers';
import { Layout, LoadingBarSection } from './components/Base';
import { configurationService } from './services';
import { Brand } from 'common/constants/enums';

const DefaultRoutes = React.lazy(() => import('./routes/DefaultRoutes'));
const SelfRegistrationRoutes = React.lazy(() => import('./routes/SelfRegistrationRoutes'));
const ErrorRoutes = React.lazy(() => import('./routes/ErrorRoutes'));

export default class App extends Component {
    constructor() {
        super();

        this.setLanguage = (language) => {
            document.documentElement.dir = language.direction.toLowerCase();
            this.setState({ language });
        };

        this.state = {
            loading: true,
            isSelfRegistrantion: false,
            clientContext: {},
            language: DEFAULT_LANGUAGE,
            setLanguage: this.setLanguage,
        };
    }

    async componentDidMount() {
        const settings = await configurationService.setupClient();

        this.setState({
            loading: false,
            showSelfRegistration: settings.isSelfRegistrationClient,
            clientContext: settings.client,
        });

        this.setLanguage(settings.language);
    }

    render() {
        const { loading, setLanguage, language, showSelfRegistration, clientContext } = this.state;

        const FaviconRenderer = () => {
            const {
                customizations: { brand },
            } = useContext(ClientContext);
            // effect to update favicon

            useEffect(() => {
                let link = document.querySelector("link[rel~='icon']");

                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    let [head] = document.getElementsByTagName('head');

                    if (head) {
                        head.appendChild(link);
                    }
                }
                if (brand?.toLowerCase() === Brand.WTP) {
                    link.href = '/wtpfavicon.ico';
                    document.title = 'Travel Assist';
                }
            }, [brand]);

            return '';
        };

        return (
            <LanguageContext.Provider
                value={{
                    setLanguage: setLanguage,
                    language: language,
                }}
            >
                <ClientContext.Provider value={{ ...clientContext }}>
                    <IntlProvider locale={language.cultureLanguageCode} messages={language.translations}>
                        {loading ? (
                            <LoadingBarSection />
                        ) : (
                            <Layout>
                                <Suspense fallback={<LoadingBarSection />}>
                                    <Switch>
                                        <Route exact path="/">
                                            <Redirect to="/404" />
                                        </Route>
                                    </Switch>

                                    {showSelfRegistration ? <SelfRegistrationRoutes /> : <DefaultRoutes />}
                                    <FaviconRenderer />

                                    <ErrorRoutes />
                                </Suspense>
                            </Layout>
                        )}
                    </IntlProvider>
                </ClientContext.Provider>
            </LanguageContext.Provider>
        );
    }
}

App.propTypes = {
    currentLanguage: PropTypes.object,
};
